import { observable, action } from "mobx";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { isValidPhoneNumber, formatPhoneNumber } from "src/utils";
import mixpanel from "mixpanel-browser";
import { MixpnaelIdentifier } from "@types";

declare const IMP: any;

const bookDictionary: { [key: string]: any } = {
  ABC: { name: "ABC Book", price: 55000 },
  RYAN: { name: "나는 라이언을 좋아해", price: 65000 },
  CAT: { name: "엄마 찾아 야옹야옹", price: 65000 },
  BDAY: { name: "내 생일을 찾아서", price: 55000 },
  HOME: { name: "우리 집에 보물이 있대요", price: 88000 },
};

interface IReceiverProps {
  [key: string]: string | null;
  receiverName: string;
  receiverPhoneNumber: string;
  receiverEmail: string | null;
}

export class InfoStore {
  [key: string]: any;

  @observable receivers: IReceiverProps[] = [
    {
      receiverName: "",
      receiverPhoneNumber: "",
      receiverEmail: "",
    },
  ];
  @observable productTheme = "1";
  @observable message = "";
  @observable senderName = "";
  @observable senderPhoneNumber = "";
  @observable isInfoPending = false;
  @observable isInfoSubmit = false;

  @action
  addReceiver = () => {
    this.receivers.push({
      receiverName: "",
      receiverPhoneNumber: "",
      receiverEmail: "",
    });
  };

  @action
  removeReceiver = (targetIndex: number) => {
    const newReceivers = this.receivers.filter((_, index) => index !== targetIndex);

    this.receivers = newReceivers;
  };

  @action
  changeReceiverInfo = (index: number, name: string, value: string) => {
    this.receivers[index][name] = value;
  };

  @action
  changeInfoStoreValue = (name: string, value: string) => {
    this[name] = value;
  };

  postGiftValidation = (country: string) => {
    const { receivers, senderName, senderPhoneNumber } = this;

    if (senderName.length === 0 || !isValidPhoneNumber(senderPhoneNumber, country)) {
      return false;
    }

    for (let i = 0; i < receivers.length; i++) {
      if (receivers[i].receiverName.length === 0 || !isValidPhoneNumber(receivers[i].receiverPhoneNumber, country)) {
        return false;
      }
    }

    return true;
  };

  @action
  postGiftInfo = async (
    bookType: string,
    bookLanguage: string,
    validateLanguage: string,
    userCountry: string,
    buyNowLink: string,
    defaultMessage: string,
    { userId, cookie }: MixpnaelIdentifier,
  ) => {
    try {
      this.isInfoSubmit = true;

      const isValidated = this.postGiftValidation(userCountry);

      if (!isValidated) return;

      this.isInfoPending = true;

      const parsedReceivers = this.receivers.map((receiver) => {
        if (receiver.receiverEmail === "") {
          receiver.receiverEmail = null;
        }

        return {
          name: receiver.receiverName,
          phone: receiver.receiverPhoneNumber,
          email: receiver.receiverEmail,
        };
      });

      const giftId = uuid();

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/orders/gift`,
        data: {
          data: {
            bookType: bookType.toUpperCase(),
            giftId,
            language: bookLanguage,
            wrapperType: this.productTheme,
            message: this.message || defaultMessage,
            buyerName: this.senderName,
            buyerPhone: this.senderPhoneNumber,
            receivers: parsedReceivers,
            country: userCountry,
            totalSpent: 0,
          },
        },
      });

      const isProduction = process.env.REACT_APP_NODE_ENV === "production";
      const checkoutLink = `${buyNowLink}:${this.receivers.length}?channel=buy_button`;
      const mixpanelQuery = userId !== null ? `userId=${userId}` : `cookie=${cookie}`;
      const debugQuery = isProduction ? "" : `&debug=true`;

      const queryString = encodeURIComponent(
        `giftId=${giftId}&bookType=${bookType}&language=${bookLanguage}&country=${userCountry}&wrapperType=${this.productTheme}&${mixpanelQuery}${debugQuery}`,
      );

      if (response.status === 200) {
        //* mixpanel
        mixpanel.track("Gift Checkout");

        if (isProduction) {
          window.open(`${checkoutLink}&ref=${queryString}`, "_self");
        } else {
          window.open(`${checkoutLink}&ref=${queryString}`, "_self");
        }
      } else {
        this.isInfoPending = false;
      }
    } catch (err) {
      console.error(err);
      this.isInfoPending = false;
    }
  };

  @action
  postGiftInfoToKcp = async (
    bookType: string,
    bookLanguage: string,
    userCountry: string,
    defaultMessage: string,
    { userId, cookie }: MixpnaelIdentifier,
  ) => {
    try {
      this.isInfoSubmit = true;

      const isValidated = this.postGiftValidation(userCountry);

      if (!isValidated) return;

      this.isInfoPending = true;

      const book = bookDictionary[bookType];
      const bookCount = this.receivers.length;
      const totalSpent = book.price * bookCount;
      const formatedPhoneNumber = formatPhoneNumber(this.senderPhoneNumber);
      const giftId = uuid();
      const parsedReceivers = this.receivers.map((receiver) => {
        if (receiver.receiverEmail === "") {
          receiver.receiverEmail = null;
        }

        return {
          name: receiver.receiverName,
          phone: receiver.receiverPhoneNumber,
          email: receiver.receiverEmail,
        };
      });

      const isProduction = process.env.REACT_APP_NODE_ENV === "production";
      const baseURL = isProduction ? "https://monvie-gift.web.app" : "https://dev-monvie-gift.web.app";
      const mixpanelQuery = userId !== null ? `userId=${userId}` : `cookie=${cookie}`;
      const debugQuery = isProduction ? "" : `&debug=true`;
      const queryString = `giftId=${giftId}&bookType=${bookType}&language=${bookLanguage}&country=${userCountry}&${mixpanelQuery}${debugQuery}`;

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/orders/gift`,
        data: {
          data: {
            bookType: bookType.toUpperCase(),
            giftId,
            language: bookLanguage,
            wrapperType: this.productTheme,
            message: this.message || defaultMessage,
            buyerName: this.senderName,
            buyerPhone: this.senderPhoneNumber,
            receivers: parsedReceivers,
            country: userCountry,
            totalSpent,
          },
        },
      });

      if (response.status === 200) {
        IMP.request_pay(
          {
            pg: "kcp",
            pay_method: "card",
            merchant_uid: giftId,
            name: `${book.name} ${bookCount}권`,
            amount: totalSpent,
            buyer_name: this.senderName,
            buyer_tel: formatedPhoneNumber,
            m_redirect_url: `${baseURL}/send?${queryString}`,
          },
          (rsp: any) => {
            try {
              let msg: string;
              if (rsp.success) {
                msg = "결제가 완료되었습니다.";
                msg += "결제 금액 : " + rsp.paid_amount;

                window.location.href = `${baseURL}/send?${queryString}`;
              } else {
                msg = rsp.error_msg;

                alert(msg);
                this.isInfoPending = false;
              }
            } catch (error) {
              this.isInfoPending = false;
            }
          },
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.isInfoPending = false;
    }
  };
}
