import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ko_KR from "./ko_KR.json";
import en_UK from "./en_UK.json";
import en_KR from "./en_KR.json";
// import en_AU from "./en_AU.json";

const resources = {
  ko_KR: {
    translation: ko_KR,
  },
  en_UK: {
    translation: en_UK,
  },
  en_KR: {
    translation: en_KR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en_UK",
  fallbackLng: "en_UK",
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.REACT_APP_NODE_ENV === "development" ? true : false,
});

export default i18n;
