import { InfoStore } from "./InfoStore";
import { AddressStore } from "./AddressStore";

export class RootStore {
  infoStore: InfoStore;
  addressStore: AddressStore;

  constructor() {
    this.infoStore = new InfoStore();
    this.addressStore = new AddressStore();
  }
}
