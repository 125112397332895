import parsePhoneNumber from "libphonenumber-js/mobile";

const city = [
  "서울특별시",
  "세종특별자치시",
  "부산광역시",
  "대구광역시",
  "인천광역시",
  "광주광역시",
  "대전광역시",
  "울산광역시",
  "서울",
  "세종",
  "부산",
  "대구",
  "인천",
  "광주",
  "대전",
  "울산",
];

export const getCityInKorea = (str1: string, str2: string) => {
  return city.includes(str1) ? str1 : str2;
};

export const isValidPhoneNumber = (phoneNumber: string, country: string) => {
  const res = parsePhoneNumber(phoneNumber, country as any);

  if (res) {
    return res.isValid();
  }

  return false;
};

export const formatPhoneNumber = (number: string) => {
  number = number.replaceAll(/[^0-9]/g, "");
  let formatNumber = "";

  try {
    if (number.length === 11) {
      formatNumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    } else if (number.length === 10) {
      formatNumber = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
  } catch (error) {
    formatNumber = number;

    console.log(error);
  }

  return formatNumber;
};
