import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { RootStore } from "src/stores";
import { App } from "src/App";
import { GlobalCSS } from "src/Global.styled";
import "src/locales/i18n";

const rootStore = new RootStore();

ReactDOM.render(
  <React.StrictMode>
    <GlobalCSS />
    <Provider store={rootStore}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);
