import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const MainPage = React.lazy(() => import("src/pages/MainPage"));
const InfoPage = React.lazy(() => import("src/pages/InfoPage"));
const SendPage = React.lazy(() => import("src/pages/SendPage"));
const AddressPage = React.lazy(() => import("src/pages/AddressPage"));
const PointPage = React.lazy(() => import("src/pages/PointPage"));
const NotFound = React.lazy(() => import("src/pages/NotFound"));
import LoaderPage from "src/pages/LoaderPage";

declare const IMP: any;

export const App = () => {
  useEffect(() => {
    IMP.init("imp99340052");

    //* mixpanel
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY as string, {
      batch_requests: true,
    });
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Suspense fallback={<LoaderPage />}>
            <MainPage />
          </Suspense>
        </Route>
        <Route path="/info">
          <Suspense fallback={<LoaderPage />}>
            <InfoPage />
          </Suspense>
        </Route>
        <Route path="/send">
          <Suspense fallback={<LoaderPage />}>
            <SendPage />
          </Suspense>
        </Route>
        <Route path="/address">
          <Suspense fallback={<LoaderPage />}>
            <AddressPage />
          </Suspense>
        </Route>
        <Route path="/point">
          <Suspense fallback={<LoaderPage />}>
            <PointPage />
          </Suspense>
        </Route>
        <Route>
          <Suspense fallback={<LoaderPage />}>
            <NotFound />
          </Suspense>
        </Route>
      </Switch>
    </Router>
  );
};
