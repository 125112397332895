import React from "react";
import styled from "styled-components";
import { MoonLoader } from "react-spinners";

const LoaderPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const LoaderWrapper = styled.div`
  margin-bottom: 20%;
`;

const LoaderPage = () => {
  return (
    <LoaderPageContainer>
      <LoaderWrapper>
        <MoonLoader size={60} color="#E74B87" />
      </LoaderWrapper>
    </LoaderPageContainer>
  );
};

export default LoaderPage;
