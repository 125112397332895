import axios, { AxiosResponse } from "axios";
import { observable, action } from "mobx";
import { getCityInKorea } from "src/utils";
import { isValidPhoneNumber } from "src/utils";

import mixpanel from "mixpanel-browser";

interface IKakaoAddress {
  address: {
    address_name: string;
    b_code: string;
    h_code: string;
    main_address_no: string;
    mountain_yn: string;
    region_1depth_name: string;
    region_2depth_name: string;
    region_3depth_h_name: string;
    region_3depth_name: string;
    sub_address_no: string;
  } | null;
  address_name: string;
  address_type: "REGION" | "ROAD";
  road_address: {
    address_name: string;
    building_name: string;
    main_building_no: string;
    region_1depth_name: string;
    region_2depth_name: string;
    region_3depth_name: string;
    road_name: string;
    sub_building_no: string;
    underground_yn: string;
    zone_no: string;
  } | null;
}

interface IKakaoKeywordAddress {
  address_name?: "서울 강남구 역삼동 683-2";
  road_address_name?: "서울 강남구 선릉로107길 3-4";
}

// interface IAddress {
//   addressName: string;
//   buildingName?: string;
//   zipCode?: string;
// }
export class AddressStore {
  [key: string]: any;

  @observable message = "";

  @observable firstName = "";
  @observable lastName = "";
  @observable receiverName = "";
  @observable phone = "";
  @observable email = "";

  @observable address = "";
  @observable detailAddress = "";
  @observable city = "";
  @observable zipCode = "";
  @observable province = "";

  @observable searchAddress = "";
  @observable kakaoAddressList: { address: string; road: string }[] = [];

  @observable isAddressSubmit = false;
  @observable isAddressPending = false;

  @observable isKakaoPending = false;
  @observable isSearchZipCode = false;

  @action
  changeAddressStoreValue = (name: string, value: string) => {
    this[name] = value;
  };

  @action
  getKakaoKeywordAddress = async (searchAddress: string) => {
    try {
      if (this.isKakaoPending) return;

      this.isKakaoPending = true;

      const response: AxiosResponse<{ documents: IKakaoKeywordAddress[] }> = await axios({
        method: "get",
        headers: {
          Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`,
        },
        url: "https://dapi.kakao.com/v2/local/search/keyword.json",
        params: {
          page: 1,
          AddressSize: 30,
          query: searchAddress,
        },
      });
      if (response.status === 200) {
        this.kakaoAddressList = response.data.documents
          .filter((address) => address?.road_address_name?.length !== 0)
          .map((address) => ({
            address: address.address_name || "",
            road: address.road_address_name || "",
          }));
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.isKakaoPending = false;
    }
  };

  @action
  getKakaoAddress = async (address: string) => {
    try {
      if (this.isSearchZipCode) return;

      this.isSearchZipCode = true;

      const response: AxiosResponse<{ documents: IKakaoAddress[] }> = await axios({
        method: "get",
        headers: {
          Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`,
        },
        url: "https://dapi.kakao.com/v2/local/search/address.json",
        params: {
          page: 1,
          AddressSize: 30,
          query: address,
        },
      });

      if (response.status === 200) {
        const address = response.data.documents[0];
        const addressName = address.address_name;
        const buildingName = address?.road_address?.building_name;
        const zipCode = address?.road_address?.zone_no;

        const region1 = address.road_address?.region_1depth_name;
        const region2 = address.road_address?.region_2depth_name;

        const city = getCityInKorea(region1 as string, region2 as string);

        this.address = addressName;
        this.zipCode = zipCode || "";
        this.detailAddress = buildingName || "";
        this.city = city;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.isSearchZipCode = false;
    }
  };

  putShippingValidation = (locale: string, country: string) => {
    const { firstName, lastName, receiverName, phone, email, address, city, zipCode, province } = this;

    if (locale === "ko-KR") {
      if (
        receiverName.length === 0 ||
        address.length === 0 ||
        // city.length === 0 ||
        zipCode.length === 0 ||
        !isValidPhoneNumber(phone, "KR")
      ) {
        return false;
      }
    } else {
      if (
        firstName.length === 0 ||
        lastName.length === 0 ||
        address.length === 0 ||
        city.length === 0 ||
        zipCode.length === 0 ||
        !isValidPhoneNumber(phone, country)
      ) {
        return false;
      }
    }

    return true;
  };

  @action
  putShippingInfo = async (orderId: string, validateLanguage: string, userCountry: string, mixpanelGroupId: string) => {
    try {
      let data;
      const isValidated = this.putShippingValidation(validateLanguage, userCountry);

      this.isAddressSubmit = true;

      if (!isValidated) return;

      this.isAddressPending = true;

      if (validateLanguage === "ko-KR") {
        data = {
          orderId,
          firstName: null,
          lastName: null,
          name: this.receiverName,
          phone: this.phone,
          email: null,
          address1: this.address,
          address2: this.detailAddress,
          city: this.city,
          province: null,
          zip: this.zipCode,
          country: userCountry,
        };
      } else {
        data = {
          orderId,
          firstName: this.firstName,
          lastName: this.lastName,
          name: null,
          phone: this.phone,
          email: this.email || null,
          address1: this.address,
          address2: this.detailAddress,
          city: this.city,
          province: null,
          zip: this.zipCode,
          country: userCountry,
        };
      }

      //* mixpanel
      mixpanel.set_group("Gift Group", [mixpanelGroupId]);
      mixpanel.identify(this.phone);

      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/orders/shipping`,
        data: {
          data,
        },
      });

      if (response.status === 200) {
        //* mixpanel
        mixpanel.track("Gift Shipping Address Submission");

        window.location.href = `${process.env.REACT_APP_MONVIE_STUDIO_URL}/?orderId=${orderId}&customerId=${this.phone}`;
      } else {
        this.isAddressPending = false;
      }
    } catch (err) {
      console.error(err);
      this.isAddressPending = false;
    }
  };
}
